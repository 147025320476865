<script lang="ts" setup>
import type { HomeBanner } from "@/types";

const props = defineProps<{ banner: HomeBanner }>();

const { open } = useAppModals();
const { allGames } = useHomePage({ immediate: false });
const { data: appInit } = useAppInitData();
const { isOneClickRegister } = useOtpGetFlow();
const { handleOpenGame } = useOpenGame(open);
const isGuest = useIsGuest();
const router = useRouter();
const { data: registerContent } = useRegisterContent();
const { registrationRewards, registrationRewardWithoutCode } = useCheckRewardsData({ immediate: isGuest.value });

const findGame = (slug: string) => allGames.value?.find((el) => el.slug === slug.replace("game=", "") || {});

const coins = computed(() => registrationRewards.value?.coins || registrationRewardWithoutCode.value?.coins);
const entries = computed(() => registrationRewards.value?.entries || registrationRewardWithoutCode.value?.entries);
const title = computed(() => registerContent.value?.title || props.banner?.title);
const subtitle = computed(() =>
	(registerContent.value?.subtitle_1 || props.banner?.smallTitle || "")
		.replace(/{fc}/g, numberFormat(coins.value ?? 0))
		.replace(/{cc}/g, numberFormat(entries.value ?? 0))
);

const buttonClick = () => {
	if (appInit.value?.isGuest || props.banner.buttonLink === "/register/") {
		if (isOneClickRegister) {
			open("LazyOModalOneClickSignup");
			return;
		}
		open("LazyOModalSignup");
		return;
	}

	if (!props.banner.buttonLink) {
		return;
	}

	if (props.banner.buttonLink.includes("facebook")) {
		window.open(props.banner.buttonLink, "_blank");
		return;
	}

	if (props.banner.buttonLink.includes("game=")) {
		const slug = findGame(props.banner.buttonLink)?.slug;
		handleOpenGame(slug);
		return;
	}
	router.push(props.banner.buttonLink);
};
</script>
<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:bg-color="banner.background"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		@click="buttonClick"
	>
		<template #default>
			<div class="logo">
				<MLogo />
			</div>
			<AText variant="taizhou" :modifiers="['medium']" as="h4">
				<span class="slide-item__header" v-html="title" />
			</AText>
		</template>
		<template #description>
			<AText
				v-if="subtitle"
				:size="{ full: 88, md: 32 }"
				as="h1"
				class="text-tlalnepantla title"
				:modifiers="['uppercase']"
			>
				<div v-html="subtitle" />
			</AText>
		</template>
		<template #actions>
			<div class="actions">
				<AButton variant="primary" class="app-banner__btn" size="2xl">
					<AText variant="tanta" :modifiers="['semibold']" class="text-tlalnepantla">{{ banner.buttonText }}</AText>
				</AButton>
			</div>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
:deep(.app-banner__content) {
	justify-content: flex-start;
	padding-top: 8px;

	@include media-breakpoint-up(md) {
		padding-top: 35px;
	}
}

:deep(.app-banner__desc) {
	@include media-breakpoint-down(md) {
		gap: 0;
	}
}

.logo {
	display: flex;
	justify-content: center;
	margin-bottom: 4px;

	@include media-breakpoint-up(md) {
		margin-bottom: 16px;
	}

	&:deep(svg) {
		width: 273px;
		height: 150px;

		@include media-breakpoint-down(md) {
			width: 102px;
			height: 56px;
		}
	}
}
.slide-item {
	&__header {
		line-height: 1.5;
		@include media-breakpoint-up(md) {
			line-height: 1.4;
		}
		:deep(.entries) {
			color: var(--chelm);
		}
	}

	&__description {
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--cannes);
		text-shadow: 0 0 6px #f30aa4;
		&.primary-text {
			font-size: 32px;
			line-height: 1.1;
			margin: 0 auto 2px;
			font-weight: 600;
			@include media-breakpoint-up(md) {
				margin: 4px auto 6px;
				font-size: 88px;
				font-weight: 700;
			}
		}
		&.secondary-text {
			font-size: 24px;
			line-height: 1.2;
			@include media-breakpoint-up(md) {
				font-size: 56px;
				margin-bottom: 10px;
			}
		}

		@include media-breakpoint-down(md) {
			font-size: 24px;
		}
	}
}

.title {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	text-shadow: 0 0 6px var(--carapicuiba);

	@include media-breakpoint-up(md) {
		gap: 16px;
	}
}
</style>
